import * as React from "react";
import { FC } from "react";
import { WordsAboutWallpaintingsEntrypoint } from "../../../entrypoints/words-about-wallpaintings";
import { LANG } from "../../../common/types/localization";
import { DefaultLayout } from "../../../components/layout/default";

export default (): FC => (
  <DefaultLayout
    lang={LANG.deCH}
    entrypoint={"words-about-wallpaintings"}
    page={"einige_worte_ueber_wandmalereien"}
  >
    <WordsAboutWallpaintingsEntrypoint lang={LANG.deCH} />
  </DefaultLayout>
);
