import * as React from "react";
import { FC, useContext } from "react";
import { FancyLine } from "../common/components/lines/fancy-line";
import { Signature } from "../common/components/signature/signature";
import {
  Heading,
  HEADING_CLASS
} from "../common/components/typography/heading";
import {
  LocalizationContext,
  useLocalized
} from "../common/localization/localize";
import { LANG } from "../common/types/localization";
import { ILocalizedPage } from "../common/types/page";
import classes from "../components/words-about-wallpaintings/words-about-wallpaintings.module.css";

export const WordsAboutWallpaintingsEntrypoint: FC<ILocalizedPage> = _props => {
  const title = useLocalized("wordsAboutWallpaintings_title");
  const { lang } = useContext(LocalizationContext);
  const isDeCh = lang === LANG.deCH;
  return (
    <>
      <Heading level={1} theme={HEADING_CLASS.MAIN}>
        {title}
      </Heading>
      <FancyLine />
      <Heading level={2} theme={HEADING_CLASS.TEXT}>
        {useLocalized("wordsAboutWallpaintings_subtitle")}
      </Heading>
      <img
        className={classes.image}
        src={"/words-about-wallpaintings/wallpainting.jpg"}
        alt={useLocalized("wordsAboutWallpaintings_image_alt")}
      />
      <p>{useLocalized("wordsAboutWallpaintings_text1")}</p>
      <p>{useLocalized("wordsAboutWallpaintings_text2")}</p>
      <p>{useLocalized("wordsAboutWallpaintings_text3")}</p>
      <p>{useLocalized("wordsAboutWallpaintings_text4")}</p>
      <p>{useLocalized("wordsAboutWallpaintings_text5")}</p>
      <p>{useLocalized("wordsAboutWallpaintings_text6")}</p>
      <p>{useLocalized("wordsAboutWallpaintings_text7")}</p>
      <p>{useLocalized("wordsAboutWallpaintings_text8")}</p>
      <p>{useLocalized("wordsAboutWallpaintings_text9")}</p>
      <p>{useLocalized("wordsAboutWallpaintings_text10")}</p>
      <p>
        {useLocalized("wordsAboutWallpaintings_text11")}m<sup>2</sup>
        {useLocalized("wordsAboutWallpaintings_text12")}
      </p>
      <p>
        {useLocalized("wordsAboutWallpaintings_text13")}m<sup>2</sup>
        {useLocalized("wordsAboutWallpaintings_text14")}
      </p>
      <p>
        {useLocalized("wordsAboutWallpaintings_text15")}
        {isDeCh && (
          <>
            m<sup>2</sup>
          </>
        )}
        {useLocalized("wordsAboutWallpaintings_text16")}
        {isDeCh && (
          <>
            m<sup>2</sup>
          </>
        )}
        {useLocalized("wordsAboutWallpaintings_text17")}
      </p>
      <p>{useLocalized("wordsAboutWallpaintings_text18")}</p>
      <p>{useLocalized("wordsAboutWallpaintings_text19")}</p>
      <p>{useLocalized("wordsAboutWallpaintings_text20")}</p>
      <p>{useLocalized("wordsAboutWallpaintings_text21")}</p>
      <p>{useLocalized("wordsAboutWallpaintings_text22")}</p>
      <Signature />
    </>
  );
};
